import cancel from 'assets/illustrations/cancel.svg'
import { Layout } from 'components/common'
import { Header } from 'components/theme'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const RootContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;

  @media (max-width: 280px) {
    width: 100%;
    margin-left: 2em;
    margin-right: 2em;
    font-size: 14px;
  }
`

const TextContainer = styled.div`
  padding: 2px 16px;
  width: 320px;
  height: 300px;
  text-align: center;
  font-weight: 700;
`

const Image = styled.img`
  height: 150px;
  width: 320px;

  @media (max-width: 578px) {
    height: 100px;
  }
`

const PageLink = styled(Link)`
  background: #9370db;
  border-radius: 5px;
  padding: 0.5em;
  color: white;
  margin-top: 1em;
`

const Cancel = () => {
  return (
    <Layout>
      <Header fullHeader={false} showNavbarLinks={false} />
      <RootContainer>
        <TextContainer>
          <Image src={cancel} alt='Cancel image' />
          <p>Payment canceled.</p>
        </TextContainer>
        <PageLink to='/upload'>Go to Upload page</PageLink>
        <PageLink to='/'>Return to Home page</PageLink>
      </RootContainer>
    </Layout>
  )
}

export default Cancel
